export const socialLinks = [
	{
		id: 1,
		name: 'Notre site principal',
		icon: 'globe',
		url: 'https://www.emulation.be/',
	},
	{
		id: 2,
		name: 'Instagram',
		icon: 'instagram',
		url: 'https://instagram.com/emulation_stavelot',
	},
	{
		id: 3,
		name: 'Facebook',
		icon: 'facebook',
		url: 'https://www.facebook.com/EmulationStavelot',
	},
	{
		id: 5,
		name: 'YouTube',
		icon: 'youtube',
		url: 'https://www.youtube.com/channel/UCOYwkFi-qVE9q-xtPV9S_nQ',
	},
];
