<script>
import PartnersElement from './PartnersElement.vue';

export default {
	components: {
		PartnersElement,
	},
	data: () => {
		return {
			partnersHeading: '',
				partners: [
					{
						id: 55,
						title: "Librairie de l'Allée Verte",
						img: require('@/assets/images/partners/classic/allee-verte.png'),
						website: 'https://www.facebook.com/librairiedelalleevertestavelot/',
					},
					{
						id: 63,
						title: 'Chorale Royal Les Artisans Réunis',
						img: require('@/assets/images/partners/classic/artisans.png'),
						website: 'https://www.facebook.com/profile.php?id=61552506712926',
					},
					{
						id: 53,
						title: 'Atelier des fleurs',
						img: require('@/assets/images/partners/classic/atelier-fleurs.png'),
						website: 'https://www.facebook.com/atelierdesfleursstavelot/',
					},
					{
						id: 50,
						title: 'Belfius Fléron',
						img: require('@/assets/images/partners/classic/belfius.png'),
						website: 'https://www.belfius.be/webapps/fr/recherche-agences/agence/heuresdouverture-fleron',
					},
					{
						id: 43,
						title: 'Bel Gusto',
						img: require('@/assets/images/partners/classic/bel-gusto.png'),
						website: 'https://www.facebook.com/belgustopastavino/?locale=fr_FR',
					},
					{
						id: 49,
						title: 'Bernard Dumoulin Menuiserie',
						img: require('@/assets/images/partners/classic/bernard-dumoulin.png'),
						website: '#',
					},
					{
						id: 1,
						title: 'Brasserie Blérot',
						img: require('@/assets/images/partners/classic/blerot.png'),
						website: 'https://www.brasserieblerot.be/',
					},
					{
						id: 2,
						title: 'Casino',
						img: require('@/assets/images/partners/classic/casino.png'),
						website: 'https://www.facebook.com/p/Pizzeria-Casino-Stavelot-100053286050236/',
					},
					{
						id: 3,
						title: 'ceran',
						img: require('@/assets/images/partners/classic/ceran.png'),
						website: 'https://www.ceran.com/fr/',
					},
					{
						id: 4,
						title: "Chateau d'Urspelt",
						img: require('@/assets/images/partners/classic/chateau-urspelt.png'),
						website: 'https://www.chateau-urspelt.lu/',
					},
					{
						id: 56,
						title: "Chez Dumoulin",
						img: require('@/assets/images/partners/classic/dumoulin.png'),
						website: 'https://chezdumoulin-stavelot.be/',
					},
					{
						id: 5,
						title: 'City Rent',
						img: require('@/assets/images/partners/classic/city-rent.png'),
						website: 'https://www.thecityrent.be',
					},
					{
						id: 54,
						title: 'Le Coin des douceurs',
						img: require('@/assets/images/partners/classic/coin-douceurs.png'),
						website: 'https://www.facebook.com/p/Le-Coin-des-Douceurs-100070306995449/',
					},
					{
						id: 6,
						title: 'Ets Constant',
						img: require('@/assets/images/partners/classic/constant.png'),
						website: '#',
					},
					{
						id: 62,
						title: 'Christopher Roxs',
						img: require('@/assets/images/partners/classic/christopher-roxs.png'),
						website: 'https://www.christopherroxs.com/',
					},
					{
						id: 65,
						title: 'Costumes et Confettis',
						img: require('@/assets/images/partners/classic/confettis.png'),
						website: 'https://www.facebook.com/p/Costumes-et-confettis-100057258677421/',
					},
					{
						id: 7,
						title: 'Cougnet Menuiserie',
						img: require('@/assets/images/partners/classic/cougnet-menuiserie.png'),
						website: 'https://www.facebook.com/p/Menuiserie-Cougnet-Benjamin-100057446890156/?locale=fr_FR',
					},
					{
						id: 8,
						title: 'Covaprint',
						img: require('@/assets/images/partners/classic/covaprint.png'),
						website: 'https://covaprint.business.site/',
					},
					{
						id: 9,
						title: 'Dalleur Social',
						img: require('@/assets/images/partners/classic/dalleur-social.png'),
						website: '#',
					},
					{
						id: 10,
						title: 'DCTP',
						img: require('@/assets/images/partners/classic/dctp.png'),
						website: 'https://troispontssports.jimdofree.com/',
					},
					{
						id: 11,
						title: 'Delhez',
						img: require('@/assets/images/partners/classic/delhez.png'),
						website: 'https://www.delhez.be/',
					},
					{
						id: 61,
						title: 'Detry',
						img: require('@/assets/images/partners/classic/detry.png'),
						website: 'https://www.detry.com/',
					},
					{
						id: 12,
						title: 'Dewez',
						img: require('@/assets/images/partners/classic/dewez.png'),
						website: '#',
					},
					{
						id: 13,
						title: 'DJP Concept',
						img: require('@/assets/images/partners/classic/djp-concept.png'),
						website: 'https://www.djpconcept.be/',
					},
					{
						id: 14,
						title: 'Domino',
						img: require('@/assets/images/partners/classic/domino.png'),
						website: 'https://www.domino-malmedy.be/',
					},
					{
						id: 15,
						title: 'Ecodis',
						img: require('@/assets/images/partners/classic/ecodis.png'),
						website: 'https://ecodis-bio-frais.be/',
					},
					{
						id: 16,
						title: 'ECS',
						img: require('@/assets/images/partners/classic/ecs.png'),
						website: 'https://ecs-malmedy.com/',
					},
					{
						id: 63,
						title: 'Epicerie Stavelot',
						img: require('@/assets/images/partners/classic/epicerie-stavelot.png'),
						website: 'https://www.facebook.com/profile.php?id=61552240149939',
					},
					{
						id: 44,
						title: 'Nettoyage Evrard',
						img: require('@/assets/images/partners/classic/evrard.png'),
						website: 'https://www.nettoyage-evrard.be/',
					},
					{
						id: 17,
						title: 'Ferme des grandes fagnes',
						img: require('@/assets/images/partners/classic/ferme-grandes-fagnes.png'),
						website: 'https://www.ferme-des-grandes-fagnes.be/',
					},
					{
						id: 18,
						title: 'Fiduciaire Hupperetz',
						img: require('@/assets/images/partners/classic/fiduciaire-hupperetz.png'),
						website: 'https://www.fiduciairehupperetz.be/',
					},
					{
						id: 19,
						title: 'Fiduciaire Thunus',
						img: require('@/assets/images/partners/classic/fiduciaire-thunus.png'),
						website: 'https://www.thunus.be/',
					},
					{
						id: 20,
						title: 'Flexia',
						img: require('@/assets/images/partners/classic/flexia.png'),
						website: 'https://www.flexia-assurances.be/',
					},
					{
						id: 21,
						title: 'Fontaine Stavelot',
						img: require('@/assets/images/partners/classic/fontaine.png'),
						website: 'https://www.fontaine-stavelot.be/',
					},
					{
						id: 22,
						title: 'Friterie Ardennaise',
						img: require('@/assets/images/partners/classic/friterie-ardennaise.png'),
						website: 'https://friterie-ardennaise.be/',
					},
					{
						id: 23,
						title: 'Friterie Sébastien',
						img: require('@/assets/images/partners/classic/friterie-sebastien.png'),
						website: 'https://www.friterie-sebastien.be/',
					},
					{
						id: 58,
						title: 'Gaspar Meubles',
						img: require('@/assets/images/partners/classic/gaspar.png'),
						website: 'https://www.facebook.com/p/Gaspar-Meubles-100057037286589/',
					},
					{
						id: 24,
						title: 'GT Bois',
						img: require('@/assets/images/partners/classic/gt-bois.png'),
						website: 'http://www.gtbois.be/fr/accueil.htm',
					},
					{
						id: 47,
						title: 'Havet & Fils',
						img: require('@/assets/images/partners/classic/havet.png'),
						website: 'https://www.stavelot.be/annuaire/havet-fils-sprl-chauffagiste',
					},
					{
						id: 25,
						title: 'Hubo',
						img: require('@/assets/images/partners/classic/hubo.png'),
						website: 'https://www.hubo.be/fr/',
					},
					{
						id: 60,
						title: 'JC Cars',
						img: require('@/assets/images/partners/classic/jc-cars.png'),
						website: 'https://partner.volvocars.be/fr/jccars-waimes',
					},
					{
						id: 51,
						title: 'JS Coiffure',
						img: require('@/assets/images/partners/classic/js-coiffure.png'),
						website: 'https://www.stavelot.be/annuaire/j-s-coiffure',
					},
					{
						id: 52,
						title: 'JS Couture',
						img: require('@/assets/images/partners/classic/js-couture.png'),
						website: 'https://www.stavelot.be/annuaire/js-couture',
					},
					{
						id: 26,
						title: 'Ets Lecoq',
						img: require('@/assets/images/partners/classic/lecoq.png'),
						website: 'https://www.facebook.com/p/Ets-Michel-LECOQ-SA-100057215809448/',
					},
					{
						id: 27,
						title: 'Lily Delices',
						img: require('@/assets/images/partners/classic/lily-delices.png'),
						website: 'https://www.facebook.com/Demoulinn/?locale=fr_FR',
					},
					{
						id: 28,
						title: "L'Office Tattoo",
						img: require('@/assets/images/partners/classic/l-office-tattoo.png'),
						website: 'https://www.facebook.com/loffice.tattoostudio/',
					},
					{
						id: 29,
						title: "L'Origan",
						img: require('@/assets/images/partners/classic/l-origan.png'),
						website: 'https://www.facebook.com/people/L-Origan/100039502344624/',
					},
					{
						id: 57,
						title: "LuxAutoland",
						img: require('@/assets/images/partners/classic/lux-autoland.png'),
						website: 'https://www.scholzen.be/fr/luxemburg/vehicules/',
					},
					{
						id: 30,
						title: 'Mamylac',
						img: require('@/assets/images/partners/classic/mamylac.png'),
						website: 'https://mamylac.be/fr/',
					},
					{
						id: 31,
						title: 'Marin Motor',
						img: require('@/assets/images/partners/classic/marin-motor.png'),
						website: 'http://marinmotor.be/',
					},
					{
						id: 32,
						title: 'Les Midis au vert',
						img: require('@/assets/images/partners/classic/midis-au-vert.png'),
						website: 'https://www.lesmidisauvert.be/',
					},
					{
						id: 64,
						title: 'Le Moulin du Bayehon',
						img: require('@/assets/images/partners/classic/moulin-bayehon.png'),
						website: 'https://www.lemoulindubayehon.be/',
					},
					{
						id: 33,
						title: 'OLM Horiticole',
						img: require('@/assets/images/partners/classic/olm.png'),
						website: 'https://m.facebook.com/people/OLM-horticole/100063904396562/?locale=ps_AF',
					},
					{
						id: 34,
						title: 'Peter Müller',
						img: require('@/assets/images/partners/classic/peter-mueller.png'),
						website: 'https://www.petermueller.be/fr/',
					},
					{
						id: 35,
						title: 'Poull Ball',
						img: require('@/assets/images/partners/classic/poull.png'),
						website: 'http://www.poull-ball.com/fr/',
					},
					{
						id: 48,
						title: 'Pita Antalya TP',
						img: require('@/assets/images/partners/classic/pita-tp.png'),
						website: 'https://www.facebook.com/p/Pita-Trois-Ponts-100063502512449/',
					},
					{
						id: 36,
						title: 'PQA',
						img: require('@/assets/images/partners/classic/PQA.png'),
						website: 'https://www.pqa.be/',
					},
					{
						id: 37,
						title: 'RLDR',
						img: require('@/assets/images/partners/classic/rldr.png'),
						website: '#',
					},
					{
						id: 46,
						title: 'Schmitz Imprimerie',
						img: require('@/assets/images/partners/classic/schmitz.png'),
						website: 'https://www.facebook.com/p/Imprimerie-Librairie-Schmitz-100057098753965/',
					},
					{
						id: 38,
						title: 'SOS-WC',
						img: require('@/assets/images/partners/classic/sos-wc.png'),
						website: 'https://www.soswc.be/',
					},
					{
						id: 39,
						title: 'Sports et nature',
						img: require('@/assets/images/partners/classic/sports-nature.png'),
						website: 'https://www.sportsetnature.be/',
					},
					{
						id: 40,
						title: "Stav'Clean",
						img: require('@/assets/images/partners/classic/stavclean.png'),
						website: 'http://stavclean.be/',
					},
					{
						id: 41,
						title: 'Thomas Piette',
						img: require('@/assets/images/partners/classic/thomas-piette.png'),
						website: 'https://www.facebook.com/p/Thomas-Piette-entreprise-100063454551505/',
					},
					{
						id: 42,
						title: 'Vitrerie Close',
						img: require('@/assets/images/partners/classic/vitrerie-close.png'),
						website: 'https://www.vitrerieclose.be/',
					},
					{
						id: 59,
						title: 'WC Services',
						img: require('@/assets/images/partners/classic/wc-services.png'),
						website: 'https://wcservices.be/',
					},
					{
						id: 45,
						title: 'Wood Pizza',
						img: require('@/assets/images/partners/classic/wood.png'),
						website: 'https://www.woodpizzacoo.com/',
					},
				],
		};
	},
};
</script>

<template>
	<div class="mt-10 sm:mt-20">
		<div class="grid grid-cols-3 sm:grid-cols-9 mt-10 sm:mt-14 gap-1">
			<PartnersElement
				v-for="partner in partners"
				:key="partner.id"
				:partner="partner"
			/>
		</div>
	</div>
</template>
