<template>
	<p id="J1874" class="font-general-medium text-2xl sm:text-5xl text-primary-dark dark:text-secondary-light">
		Journée 1874 - 28 septembre
	</p>
	<div class="relative w-full overflow-hidden mt-20 z-0">
		<div class="flex transition-transform duration-500" :style="sliderStyle" @mouseenter="pauseAutoplay"
			@mouseleave="startAutoplay">
			<div v-for="(group, index) in eventGroups" :key="index" class="flex-shrink-0 w-full p-4 mt-8">
					<div v-for="event in group" :key="event.time" class="mb-4 flex flex-col items-center">
						<h2 class="text-2xl font-bold mb-2 text-primary-light">{{ event.time }}</h2>
						<p class="text-lg text-secondary-light">{{ event.description }}</p>
						<img v-if="event.logo" :src="require(`@/assets/images/groups/${event.logo}`)" alt="Logo"
							class="mt-2 w-24 h-24 object-contain mb-20" />
					</div>
			</div>
		</div>
		<button @click="prevSlide"
			class="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white rounded-full p-2">
			⟨
		</button>
		<button @click="nextSlide"
			class="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white rounded-full p-2">
			⟩
		</button>
		<div class="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
			<button v-for="(group, index) in eventGroups" :key="index" :class="{
				'w-3 h-3 rounded-full': true,
				'bg-primary-light': index === currentIndex,
				'bg-gray-400': index !== currentIndex
			}" @click="goToSlide(index)"></button>
		</div>
	</div>
</template>


<script>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';

export default {
	name: 'Slider',
	props: {
		events: {
			type: Array,
			required: true
		},
		eventsPerSlide: {
			type: Number,
			default: 1
		},
		autoplayInterval: {
			type: Number,
			default: 3000 // Intervalle en millisecondes
		}
	},
	setup(props) {
		const currentIndex = ref(0);
		const autoplayTimer = ref(null);

		const eventGroups = computed(() => {
			const groups = [];
			for (let i = 0; i < props.events.length; i += props.eventsPerSlide) {
				groups.push(props.events.slice(i, i + props.eventsPerSlide));
			}
			return groups;
		});

		const sliderStyle = computed(() => ({
			transform: `translateX(-${currentIndex.value * 100}%)`
		}));

		const prevSlide = () => {
			currentIndex.value =
				(currentIndex.value - 1 + eventGroups.value.length) % eventGroups.value.length;
		};

		const nextSlide = () => {
			currentIndex.value =
				(currentIndex.value + 1) % eventGroups.value.length;
		};

		const goToSlide = (index) => {
			currentIndex.value = index;
		};

		const startAutoplay = () => {
			autoplayTimer.value = setInterval(nextSlide, props.autoplayInterval);
		};

		const pauseAutoplay = () => {
			clearInterval(autoplayTimer.value);
		};

		onMounted(() => {
			startAutoplay();
		});

		onBeforeUnmount(() => {
			pauseAutoplay();
		});

		return {
			currentIndex,
			eventGroups,
			sliderStyle,
			prevSlide,
			nextSlide,
			goToSlide,
			startAutoplay,
			pauseAutoplay
		};
	}
};
</script>

<style scoped></style>
