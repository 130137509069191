<script>
import feather from 'feather-icons';

export default {
	name: 'Home',
	data: () => {
		return {
			theme: '',
		};
	},
	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		feather.replace();
		this.theme = localStorage.getItem('theme') || 'light';
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<template>
	<div
		class="container"
	>
	<p
			class="font-general-medium text-2xl mb-20 sm:text-5xl text-primary-dark dark:text-secondary-light"
		>
			Le programme de nos festivités
		</p>
		<!-- Banner right illustration -->
		<div class="mt-10 flex justify-center items-center w-auto">
			<img
				src="@/assets/images/timeline.png"
				alt="Timeline"
			/>
		</div>
	</div>
</template>

<style scoped>
</style>
