<script>
import ProductsGrid from '../components/projects/ProductsGrid.vue';

export default {
	name: 'Produits dérivés',
	components: {
		ProductsGrid,
	},
};
</script>

<template>
	<p id="Products" class="font-general-medium text-2xl sm:text-5xl text-primary-dark dark:text-secondary-light">Nos produits dérivés</p>
	<div class="container mx-auto">
		<ProductsGrid />
	</div>
	<div class="mt-20">
		<p class="font-general-medium text-2xl sm:text-3xl text-primary-dark dark:text-secondary-light">
			Pour toute commande, contactez-nous via 
		<a class="underline" href="mailto:rhes@emulation.be"
			>
			notre adresse mail
		</a>
	</p>
	</div>
</template>

<style scoped></style>
