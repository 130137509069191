<script>
import PartnersElement from './PartnersElement.vue';

export default {
	components: {
		PartnersElement,
	},
	data: () => {
		return {
			partnerHeading: 'Nos partenaires',
			partners: [
			{
					id: 1,
					title: 'Nachsem',
					img: require('@/assets/images/partners/gold/nachsem.png'),
					website: 'http://www.nachsem.be/fr/accueil.htm'
			},
			{
					id: 2,
					title: 'Ardenne Prévoyante',
					img: require('@/assets/images/partners/gold/ardenne-prevoyante.png'),
					website: 'https://www.ardenneprevoyante.be/'
			},
			{
					id: 3,
					title: 'Auberge',
					img: require('@/assets/images/partners/gold/auberge.png'),
					website: 'https://aubergesaintremacle.be/'
			},
			{
					id: 4,
					title: 'Bodarwé',
					img: require('@/assets/images/partners/gold/bodarwe.png'),
					website: 'https://www.bodarwe.com/'
			},
			{
					id: 5,
					title: 'Bpost',
					img: require('@/assets/images/partners/gold/bpost.png'),
					website: 'https://www.bpost.be/fr'
			},
			{
					id: 6,
					title: 'Dauvister',
					img: require('@/assets/images/partners/gold/dauvister.png'),
					website: 'http://dauvister.com/'
			},
			{
					id: 7,
					title: 'Energys',
					img: require('@/assets/images/partners/gold/energys.png'),
					website: 'https://www.energys-belux.eu/fr/'
			},
			{
					id: 8,
					title: 'Engie',
					img: require('@/assets/images/partners/gold/engie.png'),
					website: 'https://www.engie.be/fr/'
			},
			{
					id: 9,
					title: 'Esprit Sain',
					img: require('@/assets/images/partners/gold/esprit-sain.png'),
					website: 'https://www.espritsain.be/'
			},
			{
					id: 17,
					title: 'Fabio Schmitz',
					img: require('@/assets/images/partners/gold/fabio-schmitz.png'),
					website: 'https://www.facebook.com/FabioSchmitzGraphiste/'
			},
			{
					id: 10,
					title: 'Hofman',
					img: require('@/assets/images/partners/gold/hofman.png'),
					website: 'https://www.hofman-signalisation.be/'
			},
			{
					id: 18,
					title: 'LMBA',
					img: require('@/assets/images/partners/gold/lmba.png'),
					website: 'https://stores.delhaize.be/fr/ad-delhaize-malmedy'
			},
			{
					id: 11,
					title: 'Loup Gourmand',
					img: require('@/assets/images/partners/gold/loup-gourmand.png'),
					website: 'https://leloupgourmand.be/'
			},
			{
					id: 12,
					title: 'LWL',
					img: require('@/assets/images/partners/gold/lwl.png'),
					website: 'https://www.lwlassurances.be/'
			},
			{
					id: 20,
					title: 'Ets Martin',
					img: require('@/assets/images/partners/gold/ets-martin.png'),
					website: 'https://www.facebook.com/MazoutMartin/'
			},
			{
					id: 13,
					title: 'MB',
					img: require('@/assets/images/partners/gold/MB.png'),
					website: 'https://mbgroupe.be/'
			},
			{
					id: 14,
					title: 'NB',
					img: require('@/assets/images/partners/gold/nb.png'),
					website: 'https://www.nb-beton.com/'
			},
			{
					id: 15,
					title: 'Référenceur',
					img: require('@/assets/images/partners/gold/referenceur.png'),
					website: 'https://referenceur.be/'
			},
			{
					id: 19,
					title: 'Thomas Services',
					img: require('@/assets/images/partners/gold/thomas-services.png'),
					website: 'https://www.facebook.com/depannagethomas/'
			},
			{
					id: 1,
					title: 'Vanderschaeghe',
					img: require('@/assets/images/partners/gold/vanderschaeghe.png'),
					website: 'https://www.facebook.com/cedricvanderschaeghe/?locale=fr_FR'
			},
			],
		};
	},
};
</script>

<template>
	<div class="mt-10 sm:mt-20">
		<p
			class="font-general-medium text-2xl sm:text-5xl text-primary-dark dark:text-secondary-light"
		>
			{{ partnerHeading }}
		</p>
		<div class="grid grid-cols-1 sm:grid-cols-3 mt-10 sm:mt-14 gap-2">
			<PartnersElement
				v-for="partner in partners"
				:key="partner.id"
				:partner="partner"
			/>
		</div>
	</div>
</template>
