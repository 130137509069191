<script>
export default {
	props: ['partner'],
};
</script>

<template>
	<div>
		<a :href="partner.website" target="_blank">
			<img :src="partner.img" :alt="partner.title"
				class="w-128 h-256 border border-ternary-light dark:border-ternary-dark shadow-sm rounded-lg mb-8 cursor-pointer dark:bg-secondary-light" />
		</a>
	</div>
</template>

<style lang="scss" scoped></style>
