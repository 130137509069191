<script>
import feather from 'feather-icons';
import ProductSingle from './ProductSingle.vue';
import products from '../../data/products';

export default {
	components: { ProductSingle}, 
	data: () => {
		return {
			products,
			productsHeading: 'Products',
			selectedCategory: '',
			searchProduct: '',
		};
	},
	computed: {
		// Get the filtered Products
		filteredProducts() {
			if (this.selectedCategory) {
				return this.filterProductsByCategory();
			} else if (this.searchProduct) {
				return this.filterProductsBySearch();
			}
			return this.products;
		},
	},
	methods: {
		// Filter Products by category
		filterProductsByCategory() {
			return this.Products.filter((item) => {
				let category =
					item.category.charAt(0).toUpperCase() +
					item.category.slice(1);
				console.log(category);
				return category.includes(this.selectedCategory);
			});
		},
		// Filter Products by title search
		filterProductsBySearch() {
			let product = new RegExp(this.searchProduct, 'i');
			return this.products.filter((el) => el.title.match(product));
		},
	},
	mounted() {
		feather.replace();
	},
};
</script>

<template>
	<section class="pt-10 sm:pt-14">
		<div
			class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-6 sm:gap-10"
		>
			<ProductSingle
				v-for="product in filteredProducts"
				:key="product.id"
				:product="product"
			/>
		</div>
	</section>
</template>

<style scoped></style>
