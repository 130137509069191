// Products data.

const products = [
	{
		id: 1,
		title: "Carnet de Notes",
		category: "Notre livre",
		description: "Un livre de 112 pages retraçant l'histoire de notre société.",
		img: require('@/assets/images/book-sq.png'),
	},
	{
		id: 2,
		title: '150',
		category: 'Notre vinyle',
		description: "11 morceaux qui ont marqué l'histoire de notre société.",
		img: require('@/assets/images/vinyle-sq.png'),
	},
	{
		id: 3,
		title: "Emul'tion",
		category: 'Notre bière',
		description: "L’utilisation des vertus aromatiques du houblon confère fraîcheur et saveurs d’agrumes à cette bière (6%) tout à fait exclusive.",
		img: require('@/assets/images/beer-sq.png'),
	},
];

export default products;
