<script>
import PartnersElement from './PartnersElement.vue';

export default {
	components: {
		PartnersElement,
	},
	data: () => {
		return {
			partnerHeading: '',
			partners: [
				{
					id: 1,
					title: 'Baguette',
					img: require('@/assets/images/partners/silver/baguette.png'),
					website: 'https://groupe-baguette.be/'
				},
				{
					id: 2,
					title: 'Carpe Diem',
					img: require('@/assets/images/partners/silver/carpe-diem.png'),
					website: 'https://www.carpediem-store.be/'
				},
				{
					id: 3,
					title: 'Garage Central',
					img: require('@/assets/images/partners/silver/garage-central.png'),
					website: 'https://garagecentral.be/'
				},
				{
					id: 4,
					title: 'Garage Cougnet',
					img: require('@/assets/images/partners/silver/garage-cougnet.png'),
					website: 'https://www.facebook.com/p/Garage-Cougnet-Jeremy-100057808833495/?locale=fr_FR'
				},
				{
					id: 5,
					title: 'Ixina',
					img: require('@/assets/images/partners/silver/ixina.png'),
					website: 'https://stores.ixina.be/fr/magasins/belgique-bel/verviers/ixina-verviers-IXBE4801'
				},
				{
					id: 6,
					title: 'Jardinerie Orban',
					img: require('@/assets/images/partners/silver/jardinerie-orban.png'),
					website: 'https://jardinerie-jerome-orban.be/'
				},
				{
					id: 19,
					title: 'Jehae François',
					img: require('@/assets/images/partners/silver/jehae.png'),
					website: 'https://www.terrassement-jehae.be/'
				},
				{
					id: 7,
					title: 'Jérémy Pirard',
					img: require('@/assets/images/partners/silver/jeremy-pirard.png'),
					website: 'https://m.facebook.com/p/Menuiserie-J%C3%A9r%C3%A9my-Pirard-100086304360735/'
				},
				{
					id: 8,
					title: 'JLB',
					img: require('@/assets/images/partners/silver/jlb.png'),
					website: ''
				},
				{
					id: 14,
					title: "Laval",
					img: require('@/assets/images/partners/silver/laval.png'),
					website: 'https://www.facebook.com/profile.php?id=100052468676486'
				},
				{
					id: 9,
					title: "Mobil'Ambu",
					img: require('@/assets/images/partners/silver/mobil-ambu.png'),
					website: 'https://www.facebook.com/mobilambu.srl/'
				},
				{
					id: 10,
					title: 'Nelles',
					img: require('@/assets/images/partners/silver/nelles.png'),
					website: 'https://www.nelles-freres.com/'
				},
				{
					id: 20,
					title: 'OGGI',
					img: require('@/assets/images/partners/silver/oggi.png'),
					website: 'https://www.oggiluce.fr/en_US'
				},
				{
					id: 11,
					title: 'Piscine Ondine',
					img: require('@/assets/images/partners/silver/piscine-ondine.png'),
					website: 'https://www.piscines-ondine.be/'
				},
				{
					id: 12,
					title: 'Proxy Delhaize TP',
					img: require('@/assets/images/partners/silver/proxy-tp.png'),
					website: 'https://proxytroisponts.be/'
				},
				{
					id: 13,
					title: 'Remacle Co Pro',
					img: require('@/assets/images/partners/silver/remacle.png'),
					website: '#'
				},
				{
					id: 13,
					title: 'Salmanazar',
					img: require('@/assets/images/partners/silver/salmanazar.png'),
					website: 'https://www.facebook.com/p/SalmAnazar-100086157253358'
				},
				{
					id: 14,
					title: "Scotch'Inn",
					img: require('@/assets/images/partners/silver/scotchinn.png'),
					website: 'https://www.scotchinn.be/'
				},
				{
					id: 15,
					title: 'Spirlet',
					img: require('@/assets/images/partners/silver/spirlet.png'),
					website: 'https://www.ford-spirletautomobiles.be/'
				},
				{
					id: 22,
					title: 'Thissen & Partners',
					img: require('@/assets/images/partners/silver/thissen.png'),
					website: 'https://http://www.thissen-partners.be/'
				},
				{
					id: 16,
					title: 'Tombeux Menuiserie',
					img: require('@/assets/images/partners/silver/tombeux.png'),
					website: 'https://tombeux.be/'
				},
				{
					id: 17,
					title: "Val d'Amblève",
					img: require('@/assets/images/partners/silver/val-ambleve.png'),
					website: 'https://levaldambleve.be/'
				},
				{
					id: 21,
					title: 'Zone Verte',
					img: require('@/assets/images/partners/silver/zone-verte.png'),
					website: 'https://m.facebook.com/people/Zone-Verte/100060438834974/',
				},
				{
					id: 18,
					title: 'Zune',
					img: require('@/assets/images/partners/silver/zune.png'),
					website: 'https://www.facebook.com/zuneconstructionsrl/?locale=fr_FR'
				},
			],
		};
	},
};
</script>

<template>
	<div class="mt-10 sm:mt-20">
		<div class="grid grid-cols-2 sm:grid-cols-6 mt-10 sm:mt-14 gap-1">
			<PartnersElement
				v-for="partner in partners"
				:key="partner.id"
				:partner="partner"
			/>
		</div>
	</div>
</template>
