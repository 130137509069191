<script>
import feather from 'feather-icons';
import AppHeader from './components/shared/AppHeader';
import AppFooter from './components/shared/AppFooter';

export default {
	components: {
		AppHeader,
		AppFooter,
	},
	data: () => {
		return {
			appTheme: 'dark',
			showNavbar: false,
			scrollCount: 0,
			scrollThreshold: 100,
		};
	},
	mounted() {
		feather.replace();
		window.addEventListener('scroll', this.handleScroll);

	},
	unmounted() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	updated() {
		feather.replace();
	},
	methods: {
		handleScroll() {
			this.scrollCount += window.scrollY - this.scrollCount;
			if (this.scrollCount > this.scrollThreshold) {
				this.showNavbar = true;
			} else {
				this.showNavbar = false;
			}
		},
	},
};
</script>

<template>
	<div :class="appTheme" class="">

		<div class="mx-auto">
			<div id="Header" v-if="showNavbar"
				class="fixed w-full bg-primary-dark p-1 flex items-center justify-between shadow-md transition duration-1000 ease-in-out">
				<AppHeader />
			</div>
			<div class="flex items-center justify-center h-screen">
				<img class="m-20 flex justify-center items-center w-3/5 transition duration-1000 ease-in-out transform hover:scale-90"
					v-if="!showNavbar" src="@/assets/images/logo-neutre.png" alt="Logo">
			</div>
		</div>

		<router-view v-slot="{ Component }">
			<transition name="fade" mode="out-in">
				<component :is="Component" :theme="appTheme" />
			</transition>
		</router-view>

		<!-- Scroll to top -->
		<back-to-top visibleoffset="500" right="30px" bottom="20px" class="shadow-lg">
			<i data-feather="chevron-up"></i>
		</back-to-top>

		<!-- App footer -->
		<AppFooter />
	</div>
</template>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
}

#Header {
	z-index: 1000;
}

.vue-back-to-top {
	@apply p-2 bg-ternary-dark hover:bg-ternary-dark text-white transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110;
	border-radius: 50%;
	font-size: 22px;
	line-height: 22px;
}

.fade-enter-active {
	animation: coming 0.4s;
	animation-delay: 0.2s;
	opacity: 0;
}

.fade-leave-active {
	animation: going 0.4s;
}

@keyframes going {
	from {
		transform: translateX(0);
	}

	to {
		transform: translateX(-10px);
		opacity: 0;
	}
}

@keyframes coming {
	from {
		transform: translateX(-10px);
		opacity: 0;
	}

	to {
		transform: translateX(0px);
		opacity: 1;
	}
}
</style>
