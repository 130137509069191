<script>

</script>

<template>
	<div class="container mx-auto m-40">
		<img src="@/assets/images/Ligne.png">
	</div>
</template>

<style lang="scss" scoped></style>
