<script>
export default {
	props: ['showModal', 'isOpen'],
};
</script>

<template>
	<!-- Header links -->
	<div
		:class="isOpen ? 'block' : 'hidden'"
		class="m-0 sm:ml-4 mt-5 sm:mt-3 sm:flex p-5 sm:p-0 justify-center items-center shadow-lg sm:shadow-none"
	>
		<router-link
			to="/#Timeline"
			class="font-general-medium block text-left text-2xl font-medium text-ternary-light dark:text-ternary-light hover:text-ternary-dark dark:hover:text-ternary-dark sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
			aria-label="Événements"
			>Événements</router-link
		>
		<router-link
			to="/#J1874 "
			class="font-general-medium block text-left text-2xl font-medium text-ternary-light dark:text-ternary-light hover:text-ternary-dark dark:hover:text-ternary-dark sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
			aria-label="Journée 1874"
			>Journée 1874</router-link
		>
		<router-link
			to="/#Products"
			class="font-general-medium block text-left text-2xl font-medium text-ternary-light dark:text-ternary-light hover:text-ternary-dark dark:hover:text-ternary-dark sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
			aria-label="Produits dérivés"
			>Produits</router-link
		>
		<router-link
			to="/#Partners "
			class="font-general-medium block text-left text-2xl font-medium text-ternary-light dark:text-ternary-light hover:text-ternary-dark dark:hover:text-ternary-dark sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
			aria-label="Partenaires"
			>Partenaires</router-link
		>
		<!-- <router-link
			to="/#Votes "
			class="font-general-medium block text-left text-2xl font-medium text-ternary-light dark:text-ternary-light hover:text-ternary-dark dark:hover:text-ternary-dark sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
			aria-label="Votes - Concert Juke Box"
			>Votes - Concert Juke Box</router-link
		> -->
		<div
			class="border-t-2 pt-3 sm:pt-0 sm:border-t-0 border-primary-light dark:border-secondary-dark"
		>
		</div>
	</div>
</template>

<style lang="scss" scoped></style>
