<script>
export default {
	props: ['product'],
	data() {
		return {
			isFlipped: false
		};
	},
	methods: {
		flipCard() {
			this.isFlipped = !this.isFlipped;
		}
	}
};
</script>

<template>
	<div class="rounded-xl hover:border hover:shadow-md cursor-pointer mb-10 sm:mb-0 p-5 flipped" aria-label="Products"
		@click="flipCard">
		<div class="card-content text-center aspect-w-1 aspect-h-1" :class="{ 'hidden': isFlipped }">
			<img :src="product.img" :alt="product.title" class="w-full h-full border-none mt-2 m-auto object-cover" />
			<p class="font-general-semibold text-xl text-white font-semibold mb-2 pt-5">
				{{ product.title }}
			</p>
			<span class="font-general-medium text-lg text-white ">{{ product.category
			}}</span>
		</div>
		<!-- Back of the card -->
		<div class="card-content text-center mx-auto" :class="{ 'hidden': !isFlipped }">
			<p class="font-general-medium text-2xl text-white">
				{{ product.description }}
			</p>
		</div>
	</div>
</template>

<style scoped>
.flipped .card-content .front {
	transform: rotateY(180deg);
	display: none;
	/* Cacher .front lorsque la carte est retournée */
}

.flipped .card-content .back {
	transform: rotateY(0deg);
	display: block;
	/* Afficher .back lorsque la carte est retournée */
}

.card-content {
	backface-visibility: hidden;
	transition: transform 0.6s ease;
}

.card-content .front,
.card-content .back {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.card-content .back {
	transform: rotateY(180deg);
	display: none;
	/* Cacher .back par défaut */
}</style>