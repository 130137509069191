<script>
// import AboutMe from '@/components/about/AboutMe';
// import AboutCounter from '@/components/about/AboutCounter';
import InstitutionalPartners from '@/components/about/InstitutionalPartners';
import GoldPartners from '@/components/about/GoldPartners';
import SilverPartners from '@/components/about/SilverPartners';
import ClassicPartners from '@/components/about/ClassicPartners';


import feather from 'feather-icons';

export default {
	name: 'About',
	components: {
		// AboutMe,
		// AboutCounter,
		InstitutionalPartners,
		GoldPartners,
		SilverPartners,
		ClassicPartners,
	},
	data: () => {
		return {};
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<template>
	<div  id="Partners">
		<!-- <nav>
			<router-link to="/partners#gold">Gold</router-link> |
			<router-link to="/partners#silver">Silver</router-link> |
			<router-link to="/partners#classic">Silver</router-link> | -->
			<!-- <router-link :to="{ name: 'Partners', hash: '#classic' }">Classic</router-link> -->
		<!-- </nav> -->
		<!-- About me -->
		<!-- <div class="container mx-auto">
			<AboutMe />
		</div> -->

		<!-- About counter -->
		<!-- <AboutCounter /> -->

		<!-- About counter -->
		<div id="institutional" class="container mx-auto">
			<InstitutionalPartners/>
		</div>

		<div id="gold" class="container mx-auto">
			<GoldPartners />
		</div>

		<div id="silver" class="container mx-auto">
			<SilverPartners />
		</div>

		<div id="classic" class="container mx-auto">
			<ClassicPartners />
		</div>
	</div>
</template>

<style scoped></style>
